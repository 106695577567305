import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout.component';
import DisplayPostContainer from '../components/display-post/display-post-container.component';
import DisplayPost from '../components/display-post/display-post.component';
import PaginationButtons from '../components/pagination-buttons/pagination-buttons.component';
import Seo from '../components/seo/seo.component';

const Lectures = ({ data, pageContext }) => {
  const { currentPage, numOfPages } = pageContext;
  const { nodes: lecturesNodes } = data.lectures;

  return (
    <Layout>
      <Seo
        title={'const community JavaScript lectures!'}
        description={'Read, try and practice!'}
      />
      <DisplayPostContainer>
        <DisplayPost
          headlineTitle='JavaScript Lectures'
          headlineDesc='Read, try and practice!'
          containerStyles='mb-20'
          headlineStyles='text-fuchsia-700'
          posts={lecturesNodes}
        />
        <PaginationButtons
          currentPage={currentPage}
          numOfPages={numOfPages}
          urlPath={'/lectures'}
        />
      </DisplayPostContainer>
    </Layout>
  );
};

export default Lectures;

export const query = graphql`
  query ListPaginatedLecturesQuery($skip: Int!, $limit: Int!) {
    lectures: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
      filter: { fileAbsolutePath: { regex: "/lectures/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          post
          date(formatString: "MMMM Do, YYYY")
          dateTime: date
          description
          author
          slug
          coverImage
        }
      }
    }
  }
`;
